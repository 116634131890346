@charset "utf-8";

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#0a0a0a;
$link:#D02;
$border:#DDD;

$black: #000;
$white: #e8e8e8;
$yellow: #c79f62;
$gold: #5e5341;
$red: #a20104;
$gray: #f2f2f3;

$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head: 260px;
$sp-head:60px;

$pc-side: 4.8vw;

:root{
	font-size: 14.5px;
}


@mixin ami($color, $size){
	background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15"><rect x="0" y="0" width="3" height="3" fill="#{$color}" stroke-width="0" /></svg>');
	background-size: $size;
}

@media screen and (min-width: $width){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$pc-side, //左右余白
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$pc-side, //左右余白
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$pc-side, //左右余白
		#FFF //ボディ背景
	);
}

@import "module/wire";
@import "common/header";
@import "common/cart";
@import "common/footer";
@import "page/index";

#site-wrapper,#site-footer{
	padding: 0 0 0 $pc-head;
}

.site-module{
	&.wrap{
		padding: 108px 0;
		&.mid{
			padding: 88px 0;
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.bg{
			background: $gray url("/images/common/white_tex_2x.png") repeat 0 0 / 170px;
		}
		&.graybg{
			background: $gray url("/images/common/gray_tex_2x.png") repeat 0 0 / 170px;
		}
		&.yelbg{
			background: $gray url("/images/common/yellow_tex_2x.png") repeat 0 0 / 170px;
		}
		&.redbg{
			background: $gray url("/images/common/red_tex_2x.png") repeat 0 0 / 170px;
		}
		&.border{
			border-bottom: #FFF 8px solid;
		}
	}
	
	h2,
	h3,
	h4{
		&.large{
			@include min();
			line-height: 1.1;
			text-align: center;
			font-size: 1.16rem;
			letter-spacing: .08em;
			font-weight: 700;
			margin: 0 0 40px;
			background: url("/images/common/h_icon_2x.png") no-repeat top center / 98px;
			padding: 56px 0 0;
			&:before{
				content: attr(data-eng);
				font-size: 3.2rem;
				display: block;
				margin: 0 0 8px;
			}
		}
	}
	.btnarea{
		margin: 32px 0 0;
		&.l{
			margin-top: 48px;
		}
		&.center{
			text-align: center;
		}
		a{
			display: inline-block;
			text-align: center;
			padding: 11px 56px;
			min-width: 280px;
			line-height: 1.56;
			font-size: 1.24rem;
			background: $red;
			border: $red 2px solid;
			color: #FFF;
			transition: all 200ms $ease;
			transition-property: background, color;
			&:hover{
				background: rgba($red, 0);
				color: $red;
			}
			&.ghost{
				background: none;
				color: #FFF;
				border-color: #FFF;
				&:hover{
					background: #FFF;
					color: $red;
				}
			}
			i{
				display: inline-block;
				margin: 0 8px 0 0;
				&.right{
					margin: 0 0 0 8px;
				}
			}
		}
	}
}

#follower{
	position: fixed;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;
	z-index: 1000;
	background: $red;
	border-radius: 50%;
	pointer-events: none;
	opacity: 0;
	transform: scale(0);
	transition: opacity 300ms 150ms $ease, transform 300ms 150ms $ease;
	@media screen and (max-width: $tab){
		display: none !important;
	}
	i{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("/images/common/slide_ui_2x.png") no-repeat center;
		background-size: 60px;
		transform: scale(0);
		transition: transform 300ms $ease;
	}
	&.active{
		opacity: 1;
		transform: scale(1);
		transition: opacity 300ms $ease, transform 300ms $ease;
		i{
			transform: scale(1);
			transition: transform 300ms 150ms $ease;
		}
	}
}
