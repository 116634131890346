#site-header{
	background: $black url(/images/common/black_tex_2x.png) repeat 0 0 / 170px;
	position: fixed;
	top: 0;
	left: 0;
	width: $pc-head;
	height: 100vh;
	min-height: 600px;
	z-index: 2000;
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		background: url("/images/common/header_bg.png") no-repeat left bottom 88px / #{$pc-head};
	}
	.logo{
		width: 64%;
		margin: 32px auto 16px;
		img{
			width: 100%;
		}
	}
	h1{
		@include min();
		line-height: 1;
		font-size: .8rem;
		color: $yellow;
		text-align: center;
		strong{
			font-size: 2rem;
			font-weight: 400;
			letter-spacing: .24em;
			display: block;
			margin: 8px 0 0;
		}
	}
	.gnavi{
		padding: 32px;
		padding-top: 48px;
		li{
			text-align: center;
			margin: 0 0 16px;
			a,
			.crone{
				@include min();
				font-size: 1.16rem;
				color: $white;
				display: block;
				cursor: pointer;
				user-select: none;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					left: 50%;
					bottom: -4px;
					width: 0;
					height: 1px;
					border-radius: 999px;
					background: url("/images/common/hov_tex_2x.png") repeat-x 0 0 / 40px;
					transform: translateX(-50%);
					transition: width 120ms $ease;
					
				}
				i{
					&.window{
						display: inline-block;
						font-size: .8rem;
						margin: 0 0 0 8px;
						opacity: .64;
						transform: translateY(-2px);
					}
				}
				&:hover{
					&:before{
						width: 50%;
					}
				}
			}
		}
	}
	.sns{
		position: absolute;
		left: 50%;
		bottom: 32px;
		transform: translateX(-50%);
		@include flex();
		align-items: center;
		justify-content: center;
		li{
			margin: 0 12px 0 0;
			&:last-child{
				margin: 0;
			}
			a{
				$size: 31px;
				width: $size;
				height: $size;
				border-radius: 50%;
				border: rgba(#FFF,.16) 1px solid;
				@include flex();
				align-items: center;
				justify-content: center;
				font-size: .8rem;
				color: rgba(#FFF, .72);
				position: relative;
				transition: all 200ms $ease;
				&:after{
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					border: $white 1px solid;
					border-radius: 50%;
					opacity: 0;
					transition: all 200ms $ease;
				}
				&:hover{
					font-size: 1rem;
					border-color: $white;
					background: $white;
					color: $normal;
					transform: scale(.8);
					&:after{
						opacity: 1;
						transform: scale(1.4);
					}
				}
			}
		}
	}
}

#subnavi{
	position: fixed;
	top: 0;
	left: 0;
	width: $pc-head;
	height: 100vh;
	padding: 48px 32px;
	padding-top: 108px;
	background: $black url(/images/common/black_tex_2x.png) repeat 0 0 / 170px;
	overflow: auto;
	z-index: 500;
	pointer-events: none;
	transition: left 600ms $ease;
	&.active{
		pointer-events: auto;
		left: $pc-head;
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		background: rgba(#FFF,.08);
		position: absolute;
		top: 0;
		left: 0;
	}
	.close{
		position: absolute;
		top: 16px;
		right: 16px;
		width: 30px;
		height: 30px;
		cursor: pointer;
		&:before,
		&:after{
			content: '';
			width: 100%;
			height: 1px;
			background: $white;
			position: absolute;
			top: 50%;
			left: 0;
			transform: rotate(45deg);
			transition: transform 200ms $ease;
		}
		&:after{
			transform: rotate(-45deg);
		}
		&:hover{
			&:before,
			&:after{
				transform: rotate(0);
			}
		}
	}
	h4{
		@include min();
		color: $white;
		font-size: 1.32rem;
		line-height: 1;
	}
	ul{
		margin: 40px 0 0;
		padding: 40px 0 0;
		position: relative;
		&:before{
			content: '';
			width: 32px;
			height: 1px;
			background: $white;
			position: absolute;
			top: 0;
			left: 0;
		}
		li{
			margin: 0 0 20px;
			a{
				@include min();
				font-size: 1.04rem;
				color: $white;
				display: block;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}