.site-index{
	.firstview{
		height: 100vh;
		position: relative;
		background: $black;
		&__catch{
			@include min();
			font-size: 2.4rem;
			line-height: 1.56;
			letter-spacing: .12em;
			color: #FFF;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			-webkit-font-feature-settings : normal;
			font-feature-settings : normal;
			position: absolute;
			top: 7.2vmin;
			left: 7.2vmin;
			z-index: 2;
		}
		&__bg{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-position: center;
			background-size: cover;
		}
		&__copy{
			position: absolute;
			right: 32px;
			bottom: 32px;
			color: $white;
			font-size: .72rem;
			z-index: 2;
		}
	}
	
	
	.news{
        position: relative;
        z-index: 2;
		color: $white;
		background: $black url("/images/common/black_tex_2x.png") repeat 0 0 / 170px;
		box-shadow: inset 0 0 32px rgba(#000,.32);
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width: $phone){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
			}
		}
        .leftside{
            width: 200px;
			@media screen and (max-width: $phone){
				width: auto;
				position: relative;
				margin: 0 0 20px;
			}
			h3{
				@include min();
				line-height: 1;
				font-size: 1rem;
				margin: 0 0 12px;
				font-weight: 700;
				&:before{
					content: attr(data-eng);
					font-weight: 700;
					font-size: 1.8rem;
					display: inline-block;
					margin: 0 16px 0 0;
					transform: translateX(-2px);
					@media screen and (max-width: $phone){
						font-size: 1.44rem;
						margin: 0 12px 0 0;
					}
				}
			}
			a{
				@include min();
				display: inline-block;
				font-weight: 700;
				color: $white;
				font-size: 1.08rem;
				@media screen and (max-width: $phone){
					position: absolute;
					top: 0;
					right: 0;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: .96rem;
					margin: 0 0 0 12px;
					color: $yellow;
				}
			}
        }
        ol{
            width: calc(100% - 200px);
            height: 133px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width: $tab){
				height: 150px;	
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 0;
			}
            li{
                border-bottom: rgba($white,.08) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width: $tab){
					padding: 0 0 15px;
					margin: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: $white;
					@media screen and (max-width: $tab){
						display: block;	
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 100px;
                    font-size: .88rem;
                    border: rgba($white, .16) 1px solid;
                    color: $white;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width: $tab){
						width: 100px;
						display: inline-block;
					}
                }
                h4{
                    width: calc(100% - 100px);
                    font-size: 1.12rem;
                    padding: 0 0 0 24px;
					@media screen and (max-width: $tab){
						width: auto;
						font-size: 1rem;
						padding: 6px 0 0;
					}
                }
            }
        }
	}
    
	.concept{
		position: relative;
		overflow: hidden;
		&__pic{
			position: absolute;
			width: 64%;
			height: 100%;
			transform: translateX(#{-1*$pc-side});
			z-index: 2;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		&__data{
			padding: 108px 0;
			position: relative;
			@include flex();
			justify-content: flex-end;
			z-index: 3;
			&__box{
				background: #FFF;
				padding: 48px;
				max-width: 560px;
				min-width: 480px;
				border-radius: 2px;
				h3{
					@include min();
					font-size: 1.12rem;
					line-height: 1.48;
					font-weight: 700;
					&:before{
						content: attr(data-eng);
						font-size: 2.64rem;
						letter-spacing: .024em;
						display: block;
					}
				}
				p{
					margin: 24px 0 0;
					text-align: justify;
				}
			}
		}
		&__circle{
			width: 28%;
			height: 100%;
			position: absolute;
			top: -16%;
			right: 0;
			background: url("/images/common/twos_bg.png") no-repeat top right / contain;
			opacity: .12;
		}
	}

	.bnrarea{
		#indexbnr{
			.list{
				transition-timing-function: $ease;
				&__item{
					width: 480px;
					margin: 0 2.6vmin;
					a{
						&:hover{
							img{
								opacity: 1;
							}
						}
					}
					img{
						width: 100%;
					}
				}
			}
		}
	}
	.product{
		.list{
			margin: 0 0 0 -32px;
			@include flex();
			justify-content: flex-start;
			&._active{
				li{
					opacity: 1;
					transform: translateY(0);
					@for $i from 1 through 10{
						&:nth-child(#{$i}){
							transition: all 600ms $i*120ms ease-out;
						}
					}
				}
			}
			&__item{
				width: 33.3%;
				padding: 0 0 0 32px;
				margin: 48px 0 0;
				height: auto;
				opacity: 0;
				transform: translateY(20%);
				&:nth-child(-n+3){
					margin-top: 0;
				}
				@media screen and (min-width: 1701px){
					width: 25%;
					&:nth-child(-n+4){
						margin-top: 0;
					}
				}
				a{
					background: #FFF;
					height: 100%;
					color: $normal;
					display: block;
					box-shadow: 8px 8px 16px rgba(#000,.04), 0 0 24px rgba(#000,.08);
					border-radius: 4px;
					overflow: hidden;
				}
				figure{
					padding: 62.5% 0 0;
					overflow: hidden;
					position: relative;
					img{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
                dl{
                    padding: 20px;
					dt{
						font-size: .96rem;
						line-height: 1.66;
						&:before{
							content: attr(data-label);
							background: $red;
							color: $white;
							display: block;
							width: 88px;
							font-size: .88rem;
							padding: 2px 0;
							text-align: center;
							margin: 0 0 8px;
						}
					}
					dd{
						margin: 12px 0 0;
						&.price{
							@include min();
							line-height: 1;
							font-weight: 700;
							text-align: right;
							strong{
								font-size: 2rem;
								color: $red;
								display: inline-block;
								margin: 0 2px 0 0;
							}
						}

					}
                }
			}
		}
	}
	
	.kussion{
		background: $normal;
		overflow: hidden;
		padding: 16.6vh 0;
		color: #FFF;
		@include flex();
		align-items: center;
		justify-content: center;
		position: relative;
		&__bg{
			background: url("/images/index/kussion_bg.jpg") no-repeat center center / cover;
			width: 100%;
			height: 130%;
			position: absolute;
			top: -15%;
			left: 0;
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: $black;
				opacity: .48;
			}
		}
		dl{
			text-align: center;
			position: relative;
			z-index: 2;
			dt{
				font-size: 2.2rem;
				margin: 0 0 12px;
			}
			dd{
				font-size: 1.16rem;
				line-height: 1.6;
			}
		}
	}
}