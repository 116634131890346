#site-footer{
    color: $white;
    background: $black url("/images/common/black_tex_2x.png") repeat 0 0 / 170px;
	a{
		color: $white;
	}
	.pagetop{
		@include min();
		padding: 24px;
		color: $yellow;
		letter-spacing: .12em;
		background: rgba($black, .32);
		text-align: center;
		display: block;
		border-bottom: darken($normal, 8%) 1px solid;
		position: relative;
	}
	.wrap{
		padding: 80px 0;
		position: relative;
		background: rgba($black, .12);
		border-top: lighten($normal, 6%) 1px solid;
	}
	.logos{
		@include flex();
		align-items: center;
		justify-content: center;
		margin: 0 0 32px;
		&__pic{
            width: 72px;
			margin: 0 20px 0 0;
			img{
				width: 100%;
			}
		}
		&__data{
			@include min();
			color: $yellow;
			line-height: 1;
			dt{
				font-size: .96rem;
				margin: 0 0 8px;
			}
			dd{
				font-size: 1.88rem;
			}
		}
	}
	.fnav{
		@include flex();
		justify-content: center;
		li{
			font-size: .88rem;
			&:after{
				content: '|';
				margin: 0 20px;
				display: inline-block;
				opacity: .12;
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
		}
	}
	.copy{
		text-align: center;
		display: block;
		font-size: .8rem;
		margin: 24px 0 0;
		opacity: .4;
	}
}