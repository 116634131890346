#site-cart{
	$cartSize: 88px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000;
	.cart{
		position: absolute;
		top: 0;
		right: 0;
		width: $cartSize;
		height: $cartSize;
		color: darken($yellow, 46%);
		@include flex();
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
		transition: color 200ms $ease;
		&:before,
		&:after{
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background: $yellow;
		}
		&:after{
			background: $red;
			width: 0;
			left: auto;
			right: 0;
			transition: width 200ms $ease;
		}
		&:hover{
			color: #FFF;
			&:after{
				left: 0;
				right: auto;
				width: 100%;
			}
		}
		&__box{
			text-align: center;
			&__icon{
				font-size: 1.56rem;
				display: block;
				position: relative;
			}
			&__name{
				font-size: .92rem;
				margin: 8px 0 0;
				line-height: 1;
				display: block;
			}
		}
	}
	.signin{
		position: absolute;
		top: 0;
		right: $cartSize;
		white-space: nowrap;
		li{
			&:first-child{
				a{
					border-bottom: lighten($black, 16%) 1px solid;
				}
			}
			a{
				height: $cartSize/2;
				background: $black;
				color: $white;
				font-size: .92rem;
				@include flex();
				align-items: center;
				justify-content: center;
				padding: 0 24px;
				&:hover{
					background: lighten($black, 8%);
				}
			}
		}
	}
}